<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logoEdutecno.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4" cols="12">
        <h1 class="display-2 font-weight-bold mb-3">
          Código de Validez
        </h1>
      </v-col>

      <v-col
          class="mb-5"
          cols="3"
      >
      </v-col>
      <v-col
        class="mb-5"
        cols="6"
      >
        <div class="d-flex">
        <v-text-field
            v-model="codigoValidez"
            label="Ingrese su Código"
            placeholder="Ingrese su Código"
            outlined
        ></v-text-field>
        <v-btn
            x-large
            class="ml-2"
            color="warning"
            dark
            @click="buscar"
        >
          Buscar
        </v-btn>
        </div>
      </v-col>
      <v-col
          class="mb-5"
          cols="3"
      >
      </v-col>
      <template v-if="mostrarCurso">
      <v-col
        class="mb-1"
        cols="12"
      >
        <h2>Nombre Alumno</h2>
        <h3>{{alumno.nombre_alumno}} {{alumno.apellido_paterno_alumno}} {{alumno.apellido_materno_alumno}}</h3>
      </v-col>

      <v-col
            class="mb-1"
            cols="12" >
        <h2>Curso</h2>
        <h3>{{nombreCurso}}</h3>

      </v-col>

      <v-col
          class="mb-1"
          cols="12"
      >
        <h2>Modalidad</h2>
        <h3 class="mt-1 ml-2"> {{modality}}</h3>
      </v-col>

      <v-col
        class="mb-1"
        cols="12"
      >
        <h2>Duración del Curso</h2>
          <h3 class="mt-1 ml-2"> Realizado desde el {{data.fecha_inicio}} hasta el {{data.fecha_fin}}</h3>
      </v-col>

      </template>
      <template v-if="noHay">
        <v-col
            class="mb-5"
            cols="3"
        >
        </v-col>
        <v-col
            class="mb-5"
            cols="6"
        >
          <h1 justify="center">No se ha encontrado alumno relacionado al código {{this.codigoNoEncontrado}}</h1>
        </v-col>
        <v-col
            class="mb-5"
            cols="3"
        >
        </v-col>



      </template>
    </v-row>
  </v-container>
</template>

<script>
  import ApiService from "../webServices/api.service";

  export default {
    name: 'CodigoValidez',
    data() {
      return {
        codigoValidez: "",
        codigoNoEncontrado: "",
        mostrarCurso: false,
        noHay: false,
        is_sence: false,
        nombreCurso: "",
        alumno: {},
        data:{},
        modality:""
      }
    },
    methods: {
      buscar: function () {

        const url = `alumnos/curso-codigo-validez?codigoValidez=${this.codigoValidez}`
        return ApiService
            .post(url)
            .then(response => {
              this.data =  response.data
              this.alumno =  response.data.alumno
              if(this.alumno){
                this.mostrarCurso = true
                this.noHay = false
                if(this.data.procen_franquiciable > 0)
                {
                    this.is_sence = true;
                    this.nombreCurso = this.data.orden_compra.course_name_in_sence
                    this.modality = this.data.orden_compra.modality_name
                }
                else
                {
                  this.nombreCurso = this.data.platform_version_course.course_version.course_ldm.description
                  this.modality = this.data.platform_version_course.course_version.course_ldm.modality.name
                  this.is_sence = false;
                }

              }else{
                this.codigoNoEncontrado = this.codigoValidez
                this.mostrarCurso = false
                this.noHay = true
              }

            })
            .catch(error => {
              console.log(error)
            })
      },
    },
  }
</script>
