import axios from 'axios'


// Global variables
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
const clientId = process.env.VUE_APP_CLIENT_ID
const clientSecret = process.env.VUE_APP_CLIENT_SECRET
const grantType = process.env.VUE_APP_GRANT_TYPE
//const user = "17671173"
//const pass = "17671173"


// Add authorization token to all request
axios.interceptors.request.use(config => {
  const token = window.localStorage.getItem('id_token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers['Content-Type'] = 'application/json'
  config.headers.Accept = 'application/json'
  config.headers.idperfil = window.localStorage.getItem('id_perfil')
  config.headers.idusuario = window.localStorage.getItem('id_usuario')
  return config
}, error => Promise.reject(error))

// Check all responses in order to handle authorization error
axios.interceptors.response.use(response => response, error => {
  const originalRequest = error.config

  if (error.response.status === 401 && !originalRequest._retry) {
    console.log("no hay conexion")
    return axios.post(`${apiBaseUrl}oauth/token`, {
      client_id: clientId,
      client_secret: clientSecret,
      scope: '*',
      grant_type: grantType,
     // username: user,
      //password: pass,
    }, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(res => {
      if (res.status === 201 || res.status === 200) {
        window.localStorage.removeItem('id_token')
        window.localStorage.setItem('id_token', res.data.access_token)
        axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`
        return axios(originalRequest)
      }
      //router.push('login')
    })
  }
  return error
})

const ApiService = {
  /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
  // eslint-disable-next-line no-unused-vars
  async get(resource, slug = '') {
    return axios.get(`${apiBaseUrl}api/${resource}`).then(response => response).catch(error => {
      console.log(error)
      throw error
    })
  },

  /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
  post(resource, params) {
    return axios.post(`${apiBaseUrl}api/${resource}`, params)
      .then(response => response).catch(error => {
        console.log(error)
        throw error
      })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postDirect(resource, params) {
    return axios.post(`${apiBaseUrl}${resource}`, params)
      .then(response => response).catch(error => {
        console.log(error)
        throw error
      })
  },

  /**
     * Set the POST HTTP request with formdata
     * @param resource
     * @param formData
     * @returns {*}
     */
  postFormdata(resource, formData) {
    return axios.post(`${apiBaseUrl}api/${resource}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: formData,
    }).then(response => {
      console.log(response)
      return response
    })
  },

  /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
  update(resource, slug, params) {
    return axios.update(`${apiBaseUrl}api/${resource}/${slug}`, {
      params,
    }).then(response => {
      console.log(response)
      return response
    })
  },

  /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
  put(resource, params) {
    return axios.put(`${apiBaseUrl}api/${resource}`, {
      params,
    }).then(response => {
      console.log(response)
      return response
    })
  },

  /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
  delete(resource) {
    return axios.delete(`${apiBaseUrl}api/${resource}`).then(response => {
      console.log(response)
      return response
    })
  },


  postGetFile(resource, params, fileName) {
    return axios({
      url: `${apiBaseUrl}api/${resource}`,
      method: 'POST',
      data: params,
      responseType: 'blob',
    }).then(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
      return response
    })
  },
}

export default ApiService
